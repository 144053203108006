export async function useExtraInfoItem(deliveryKey: string) {
  const localePath = useLocalePath()

  const query = {
    locale: localePath(''),
    vse: useRoute().query.vse,
    contentId: useRoute().query.contentId,
    deliveryKey: deliveryKey,
  }

  return useFetch('/api/cms/getExtraInfoItem', {
    query,
    immediate: false,
  })
}
